<template>
  <div style="width:100%;">
    <el-row>
      <el-col>
        <div class="list-box">
          <el-table border stripe :data="list">
            <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
            <el-table-column prop="organizationName" align="center" label="归属机构"></el-table-column>
            <el-table-column prop="sysName" align="center" label="人员名称"></el-table-column>
            <el-table-column prop="sysPhone" align="center" label="手机号"></el-table-column>
            <el-table-column width="120" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type='text' @click="setApprovalPeople(scope.row.sysId)">设置审批人</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col>
        <el-button type="info" @click="dialogClose()">关闭窗口</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { querySysUserListById, addApproval } from '@/api/setting';
export default {
  props: ['isAddDialog', 'setId'],
  components: {

  },
  data() {
    return {
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  computed: {

  },
  mounted() {
    this.setId && this.getDateil();

  },
  methods: {
    setApprovalPeople(id) {
      let that = this;
      let params = {
        id: that.setId,
        approvalId: id,
      }
      addApproval(params)
        .then((response) => {
          if (response.code == 200) {
            that.dialogClose();
            that.$emit('getTreeList')
            that.$emit('getList')
          }
          that.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });


        });
    },
    dialogClose() {
      this.active = 0;
      this.$emit('update:isAddDialog', false)
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDateil();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getDateil();
    },


    getDateil() {
      let that = this;
      let params = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        // organizationId: that.setId,
      };

      querySysUserListById(params)
        .then((response) => {
          if (response.code == 200) {
            console.log(response.data.records);
            that.total = response.data.total;
            that.list = response.data.records;

          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        });

    },
  }
}
</script>
<style scoped>
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  width: 631px;

  max-height: 600px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
</style>