<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="120px" :disabled="!editShow">
    <el-form-item label="机构名称" prop="organizationName">
      <el-input style="width:400px;" placeholder="中英文、字母或数字组合,长度小于16位" v-model="form.organizationName" maxlength="16" v-if="editShow"></el-input>
      <div class="content-text" v-else>{{form.organizationName}}</div>
    </el-form-item>
    <OrganizationSelect v-model="form.pid" label="上级机构" required :showAll="false" :isCheck="false" style="width:520px"></OrganizationSelect>
    <el-form-item label="机构类型" prop="organizationType">
      <el-select v-model="form.organizationType" placeholder="请选择机构类型" style="width:400px" :disabled="!editShow">
        <el-option label="机构" :value="0"></el-option>
        <el-option label="部门" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="显示顺序" prop="sort">
      <el-input style="width:400px;" placeholder="显示顺序" v-model="form.sort" maxlength="6" :disabled="!editShow"></el-input>
    </el-form-item>
    <el-form-item label="是否需要审批">
      <el-radio-group v-model="form.isApproval" :disabled="!editShow">
        <el-radio :label="0">否</el-radio>
        <el-radio :label="1">是</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-button type="primary" v-debounce="onSubmit" v-if="!editType">立即创建</el-button>
    <el-button type="primary" v-debounce="onSubmit" v-if="editType == 2">立即保存</el-button>
    <el-button @click="dialogClose">关闭窗口</el-button>
  </el-form>
</template>
<script>
import { organizationAdd, organizationEdit, organizationDetail } from '@/api/setting';
import { getCookies } from '@/utils/utils'
import OrganizationSelect from '@/components/OrganizationSelect.vue'
export default {
  props: ['isAddDialog', 'editId', 'editType', 'pId'],
  components: { OrganizationSelect },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('电话不可为空'));
      }
      if (value.length < 6) {
        return callback(new Error('号码至少7位以上'));
      }
      // let reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
      // let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      // if(reg.test(value)){
      if (String(value).length > 10) {
        callback()
      } else {
        callback(new Error('请输入正确手机号码'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      orgId: getCookies('organizationId'),
      form: {
        organizationName: '',                   //机构名称
        isApproval: 1,                         //是否需要审批 1是 0否
        organizationType: 0,                  // 机构类型 1部门 0机构
        pid: getCookies('organizationId'),                              // 父机构ID
        sort: ''             //显示顺序
      },
      rules: {
        organizationName: [
          { required: true, message: '机构名称不可为空', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '显示顺序不可为空', trigger: 'blur' },
        ],
        pid: [
          { required: true, message: '请选择父机构', trigger: 'change' },
        ],
        organizationType: [
          { required: true, message: '请选择机构类型', trigger: 'change' },
        ],
      },

    }
  },
  computed: {
    editShow() {
      return this.editType == 2 || !this.editType
    }
  },
  created() {
    this.form.pid = this.pId
    this.editType && this.editId && this.getDateil();
  },
  methods: {
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/[^\w\.\/]/ig, '');
    },
    getDateil() {
      organizationDetail(this.editId).then((response) => {
        if (response.code == 200) {
          Object.assign(this.form, response.data)
        } else {
          that.$message.error({
            title: '错误',
            message: response.msg,
            duration: 1500
          });
        }

      })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      let success = response => {
        if (response.code == 200) {
          this.$message.success('操作成功')
          this.dialogClose();
          this.$emit('getTreeList')
          this.$emit('getList')
        } else {
          this.$message.error({
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      }
      if (form.id) {
        organizationEdit(form).then(success)
      } else {
        organizationAdd(form).then(success)
      }
    },
  },
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>