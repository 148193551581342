<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" size="mini" style="float:left;margin:15px 15px 0px 0px;" icon="el-icon-plus" @click.native="isAddDialog = true;editType = ''">新增分支机构</el-button>
        <el-input style="width:180px;float:left;margin:15px 15px 0px 0px;" size="mini" placeholder="请输入名称搜索" v-model="search"></el-input>

        <el-button style="float:left;margin:15px 15px 0px 0px;" type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        <!-- <el-button style="float:left;margin:15px 15px 0px 0px;" type="default" size="mini" @click="clearSearch()">重置</el-button> -->
      </el-col>
      <el-col :span="4">
        <el-tree style="margin-top:20px;" :data="dataTree" :props="defaultProps" accordion @node-click="handleNodeClick" node-key="id" :default-expanded-keys="[orgId]"></el-tree>
      </el-col>
      <el-col :span="20">
        <el-table stripe :data="list" style="margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" height="calc(100vh - 200px)">
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="organizationName" label="机构名称" align="center">
            <template slot-scope="scope">
              <div class="goto-detail" @click="editDetail(scope.row.id, 1)">{{scope.row.organizationName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="organizationName" label="机构类型" align="center">
            <template slot-scope="scope">
              {{scope.row.organizationType==1?'部门':'机构'}}
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="顺序" align="center"></el-table-column>
          <el-table-column prop="isApproval" align="center" label="是否需要审批" width="150">
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.isApproval ? 'primary' : 'warning'" disable-transitions>{{scope.row.isApproval ?'需要':'不需要'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="isDisable" align="center" label="状态" width="150">
            <template slot-scope="scope">
              <el-tag size="mini" :type="!scope.row.organizationStatus ? 'primary' : 'warning'" disable-transitions>{{!scope.row.organizationStatus ?'已启用':'已禁用'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="address" align="center" label="审批人">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.approvalId" @click="setApprovalPeople(scope.row.id)">{{scope.row.approvalName}}</el-button>
              <el-button type="text" icon="el-icon-setting" v-else @click="setApprovalPeople(scope.row.id)">设置</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.id,2)">编辑</el-button>
              <el-button type="text" icon="el-icon-delete" style="color:red" @click="setDisable(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog :destroy-on-close="true" :title="isAddDialogTitle[Number(editType)]" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="640px">
      <Add v-if="isAddDialog" :isAddDialog.sync="isAddDialog" @getList="getList" @getTreeList="getTreeList" :editId.sync="editId" :editType.sync="editType" :pId="orgId"></Add>
    </el-dialog>
    <el-dialog :destroy-on-close="true" title="设置机构审批人" :visible.sync="isSetDialog" :lock-scroll="false" :close-on-click-modal="false" width="740px">
      <SetApprovalPeople v-if="isSetDialog" @getList="getList" @getTreeList="getTreeList" :isAddDialog.sync="isSetDialog" :setId.sync="setId"></SetApprovalPeople>
    </el-dialog>
  </div>

</template>
<script>
import { organizationList, getOrganizationTree, delOrganizationById } from '@/api/setting';
import Add from './components/add.vue'
import SetApprovalPeople from './components/setApprovalPeople.vue'
import { getCookies } from '@/utils/utils'
export default {
  name: 'outlets',
  components: {
    Add,
    SetApprovalPeople
  },
  data() {
    return {
      // tree
      dataTree: [],
      defaultProps: {
        children: 'children',
        label: 'organizationName'
      },

      //add Dialog
      isAddDialog: false,
      isDisable: '',           //状态
      isAddDialogTitle: ['新增机构详情', '分支机构详情', '编辑分支机构'],
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
      //set Approval People Dialog
      isSetDialog: false,
      search: '', //搜索
      organizationArea: '',
      moreSelectValue: '',

      orgId: getCookies('organizationId'),
      list: [
      ],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  watch: {
    isAddDialog(n, o) {
      if (!n) {
        this.orgId = getCookies('organizationId')
        // this.getTreeList();
        // this.getList();
      }
    },
    isSetDialog(n, o) {
      if (!n) {
        this.orgId = getCookies('organizationId')
        // this.getTreeList();
        // this.getList();
      }
    },
  },
  created() {
    this.getTreeList();
    this.getList();

  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    setDisable(id) {
      this.$confirm('是否删除该分支机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOrganizationById(id)
          .then((response) => {
            if (response.code == 200) {
              this.handleCurrentChange(1);
              this.getTreeList();
            }
            this.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    handleNodeClick(data) {
      console.log(data.id);
      this.orgId = data.id
      this.handleCurrentChange(1);
    },
    getTreeList() {
      let that = this;
      // getOrganizationTree(1)
      getOrganizationTree(this.orgId)
        .then((response) => {
          if (response.code == 200) {

            that.dataTree = [response.data];
            console.log(that.dataTree);
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    getList() {
      let that = this;

      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        orgId: this.orgId,
        search: this.search
      };
      organizationList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            that.list = response.data.records;

          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    clearSearch() {
      this.search = '';
      this.isDisable = '';
      this.organizationArea = '';
    },
    setApprovalPeople(id) {
      this.setId = id;
      this.isSetDialog = true;
    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isAddDialog = true;
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";

.add-btn {
  float: left;
  margin-right: 15px;
}
.more-select {
  margin-top: 15px;
  width: 10%;
  float: left;
  margin-right: 15px;
  min-width: 150px;
}
.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}

.goto-detail {
  color: $hoverBlue;
}
.goto-detail:hover {
  cursor: pointer;
}
</style>